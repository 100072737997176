




















































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { transactionStoreActions, transactionStoreGetter } from '@/store/transaction.store';
import Transaction from '@/models/Transaction';
import moment from 'moment';

const TransactionStore = namespace('transactions');

@Component({
    components: {
        TransactionTableComponent: () => import(
            /* webpackChunkName: "TransactionTableComponent" */
            '@/components/TransactionTable.component.vue')
    }
})
export default class TransactionsView extends Vue {
    @TransactionStore.Action(transactionStoreActions.GET)
    private loadTransactions!: (payload: {start: string, end: string}) => Promise<Transaction[]>;

    @TransactionStore.Getter(transactionStoreGetter.TRANSACTIONS)
    private transactions!: Transaction[];

    private isLoading = false;

    public startMenu: boolean = false;
    public endMenu: boolean = false;
    private startDate: string | null = null;
    public endDate: string | null = null;
    public searchValue: string = '';

    public async created() {
        const today = moment(new Date());
        this.endDate = today.format('yyyy-MM-DD');
        today.set({ date: 1 });
        this.startDate = today.format('yyyy-MM-DD');

        await this.getTransactions();
    }

    public async getTransactions() {
        try {
            this.isLoading = true;
            const payload = {
                start: this.startDate!,
                end: this.endDate!
            };
            await this.loadTransactions(payload);
        } catch (e) {
            this.$notifyErrorSimplified('TRANSACTIONS.ERROR');
        } finally {
            this.isLoading = false;
        }
    }
}
